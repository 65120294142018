import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Select, Spin, Space } from "antd";

import axios from "axios";
import { BASE_URL } from "../constants/Constants";
import { useHistory } from "react-router-dom";

import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const AddProductForm = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [thumbnailFileList, setThumbnailFileList] = useState([]);
  const [additionalFileList, setAdditionalFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const handleThumbnailFileChange = ({ fileList }) => {
    setThumbnailFileList(fileList.slice(-1)); // Only keep the last uploaded file
    console.log("thumbnail file", fileList);
  };

  const handleAdditionalFileChange = ({ fileList }) => {
    setAdditionalFileList(fileList);
    console.log("additional files", fileList);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/category/all-unhide`);
      const items = response?.data;
      setCategories(items);
      console.log(items);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    const selectedCategory = categories.find(
      (category) => category.categoryId === categoryId
    );
    setSubcategories(selectedCategory ? selectedCategory.subcategoryList : []);
  };

  const handleAddProduct = async () => {
    try {
      const values = await form.validateFields();

      // Construct the product data object
      const productData = {
        productName: values.productName,
        productQuantity: values.productQuantity,
        productPrice: parseFloat(values.purchasePrice),
        purRate: parseFloat(values.purchasePrice),
        mrp: parseFloat(values.mrpPrice),
        productPercent: 10.0,
        vatRate: 5.0,
        status: 1,
        reqStatus: 0,
        category: values.productCategories,
        brand: values.brand,
        box: 1,
        weight: values.weight,
        hsnSc: "HSN1234", // Assuming it's a static value for now
        createdBy: "admin", // Assuming it's a static value for now
        createdTime: new Date().toISOString(), // Current time
        modifiedBy: "admin", // Assuming it's a static value for now
        modifiedTime: new Date().toISOString(), // Current time
        isProductHidden: false,
        prdDesc: values.description,
        prdDesc2: values.description2.join("\n"),
        minStk: values.stockQuantity, // Assuming it's a static value for now
        prdPurity: values.purity, // Assuming it's a static value for now
        categoryId: selectedCategory, // Use the selected category
        subcategoryId: values.subcategories, // Use the selected subcategory
        gstNo: "GSTIN001", // Assuming it's a static value for now
      };
      setIsLoading(true);
      // Make API request to add product
      const response = await axios.post(
        `${BASE_URL}/products/create`,
        productData
      );

      message.success("Product added successfully");

      form.resetFields();
      onClose();
      window.location.reload();
    } catch (error) {
      // Handle errors, e.g., show an error message
      message.error("Failed to add product");
    }
  };

  return (
    <Modal
      title="Add Product"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="add"
          style={{
            border: "1px solid rgb(18, 127, 190)",
            color: "rgb(18, 127, 190)",
          }}
          onClick={handleAddProduct}
        >
          {isLoading ? <Spin /> : "Add Product"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Product Name"
          name="productName"
          rules={[{ required: true, message: "Please enter the product name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Short Description"
          name="description"
          rules={[
            { required: true, message: "Please enter the product description" },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Detailed Description">
          <Form.List name="description2">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={name}
                      fieldKey={fieldKey}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a description point",
                        },
                      ]}
                    >
                      <Input placeholder="Enter a description point" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Point
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          label="Product Brand"
          name="brand"
          rules={[
            { required: true, message: "Please enter the product Brand" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Product Purity"
          name="purity"
          rules={[
            { required: true, message: "Please enter the product purity " },
          ]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Product Category"
          name="productCategories"
          rules={[
            { required: true, message: "Please select a product category" },
          ]}
        >
          <Select
            placeholder="Select a Category"
            onChange={handleCategoryChange}
          >
            {categories.map((category) => (
              <Option key={category.categoryId} value={category.categoryId}>
                {category.categoryName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Product Sub-Category" name="subcategories">
          <Select placeholder="Select a Sub-Category">
            {subcategories.map((subcategory) => (
              <Option
                key={subcategory.subCategoryId}
                value={subcategory.subCategoryId}
              >
                {subcategory?.subCategoryName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Purchase Price"
          name="purchasePrice"
          rules={[
            {
              required: true,
              message: "Please enter the product purchase price",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="MRP Price"
          name="mrpPrice"
          rules={[
            { required: true, message: "Please enter the product MRP price" },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Product Quantity (per pack)"
          name="productQuantity"
          rules={[
            {
              required: true,
              message: "Please enter the product stock quantity",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Stock Quantity"
          name="stockQuantity"
          rules={[
            {
              required: true,
              message: "Please enter the product stock quantity",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Product weight"
          name="weight"
          rules={[
            { required: true, message: "Please enter the product weight " },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <p>
            <b>Note:</b> You can upload images after creating the product.{" "}
          </p>
          <UploadOutlined style={{ color: "black", fontSize: "22px" }} />
        </div>
      </Form>
    </Modal>
  );
};

export default AddProductForm;
