import React, { useEffect, useState } from "react";
import moment from "moment";

// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom"; // Import useHistory instead of useNavigate

import { Button, DatePicker, Input, Modal, Space, message } from "antd";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";
// import "./Modal.css";
const ReturnRequestDetails = ({ data }) => {
  // const navigate = useNavigate();
  const history = useHistory(); // Use useHistory hook for navigation
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  //   modal useState
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Space>
        <Button
          type="primary"
          size="small"
          className="bg-blue-500 hover:bg-blue-700 rounded"
          onClick={showModal}
        >
          View
        </Button>
      </Space>
      <Modal
        visible={open}
        title="Return Request Details"
        onOk={handleOk}
        onCancel={handleCancel}
        // className="text-black"
        // style={{ color: '#ffff' }} // Adjust the width here
        width={1000}
        okButtonProps={{
          style: {
            color: "black",
            height: "auto",
            fontSize: "14px",
            maxHeight: "100vh",
          },
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        {/* Your content here */}
        <div className="text-black">
          {/* < > */}

          <div className="flex flex-col gap-9 bg-transparent">
            {/* <!-- Contact Form --> */}
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-none dark:bg-transparent">
              <div className="mb-3 flex gap-10">
                <div className="flex">
                  <p className="text-[1.05rem] font-semibold		">Return Reason:</p>
                  <p className="  text-green mx-2 border-x-2 px-2 text-center text-[1rem] ">
                    {data.returnReason}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-[1.05rem] font-semibold		">
                    Return Comment:
                  </p>
                  <p className="  text-green mx-2 border-x-2 px-2 text-center text-[1rem] ">
                    {data.comment}
                  </p>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "80px" }}
              >
                <div>
                  <p
                    className="text-[1.05rem] font-semibold"
                    style={{ marginBottom: "20px" }}
                  >
                    Product Details:
                  </p>
                  <p>
                    <strong>Product Id:</strong> {data.productid}
                  </p>
                  <p>
                    <strong>Product Name:</strong> {data.productName}
                  </p>
                  <p>
                    <strong>Order Amount:</strong> Rs {data.finalOrderAmount}
                  </p>
                </div>
                <div>
                  <p
                    className="text-[1.05rem] font-semibold"
                    style={{ marginBottom: "20px" }}
                  >
                    Bank Account Details:
                  </p>
                  <p>
                    <strong>Account Number:</strong> {data.accountNumber}
                  </p>
                  <p>
                    <strong>Account Holder Name:</strong>{" "}
                    {data.accountHolderName}
                  </p>
                  <p>
                    <strong>Bank Name:</strong> {data.bankName}
                  </p>
                  <p>
                    <strong>IFSC Code:</strong> {data.ifscCode}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </> */}
        </div>
      </Modal>
    </>
  );
};

export default ReturnRequestDetails;
