import {
  Row,
  Col,
  Card,
  Tooltip,
  Radio,
  Typography,
  Modal,
  Button,
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import axios from "axios";

import React, { useEffect, useState } from "react";
import AddProductForm from "../components/AddProductForm";
import UpdateProductForm from "../components/UpdateProductForm";
import AddProductImages from "../components/AddProductImages";
import { BASE_URL } from "../constants/Constants";

const { Meta } = Card;
const { Title } = Typography;

const Product = () => {
  const userId = localStorage.getItem("userId");
  const [product, setProduct] = useState([]);
  const [isAddProductModalVisible, setIsAddProductModalVisible] =
    useState(false);
  const [isAddImageModalVisible, setIsAddImageModalVisible] = useState(false);
  const [isUpdateProductModalVisible, setIsUpdateProductModalVisible] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isShowingHiddenProducts, setIsShowingHiddenProducts] = useState(false);

  const showAddProductModal = () => {
    setIsAddProductModalVisible(true);
  };

  const hideAddProductModal = () => {
    setIsAddProductModalVisible(false);
  };

  const showUpdateProductModal = (product) => {
    setSelectedProduct(product);
    setIsUpdateProductModalVisible(true);
  };

  const hideUpdateProductModal = () => {
    setSelectedProduct(null);
    setIsUpdateProductModalVisible(false);
  };

  const showAddImageModal = (product) => {
    setSelectedProduct(product);
    setIsAddImageModalVisible(true);
  };

  const hideAddImageModal = () => {
    setSelectedProduct(null);
    setIsAddImageModalVisible(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsShowingHiddenProducts(false);
      const response = await axios.get(`${BASE_URL}/products/allProductList`);

      const items = response?.data;
      // Sort products by creation date in descending order
      const sortedItems = items.sort(
        (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
      );

      setProduct(sortedItems);

      console.log(sortedItems);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProductHide = async (product) => {
    try {
      const productId = product.productId;
      console.log(productId);

      const response = await axios.post(
        `${BASE_URL}/products/hide?productId=${productId}`
      );

      const updatedProducts = await axios.get(
        `${BASE_URL}/products/allProductList`
      );
      setProduct(updatedProducts.data);

      console.log("Product Deleted Successfully");
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const fetchHiddenProducts = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/products/allHiddenProductList`
      );
      const hiddenItems = response?.data;

      setProduct(hiddenItems);
      setIsShowingHiddenProducts(true);
      console.log(hiddenItems);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleProductUnhide = async (product) => {
    try {
      const productId = product.productId;
      console.log(productId);

      await axios.post(`${BASE_URL}/products/unhide?productId=${productId}`);

      const updatedProducts = await axios.get(
        `${BASE_URL}/products/allHiddenProductList`
      );
      setProduct(updatedProducts.data);

      console.log("Product Unhidden Successfully");
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  return userId ? (
    <React.Fragment>
      <div>
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Product Data"
              extra={
                <React.Fragment>
                  {isShowingHiddenProducts ? (
                    <Radio.Group
                      defaultValue="a"
                      style={{ marginRight: "20px" }}
                    >
                      <Radio.Button
                        value="a"
                        onClick={fetchData}
                        type="primary"
                      >
                        All Products
                      </Radio.Button>
                    </Radio.Group>
                  ) : (
                    <Radio.Group
                      defaultValue="a"
                      style={{ marginRight: "20px" }}
                    >
                      <Radio.Button
                        value="a"
                        onClick={fetchHiddenProducts}
                        type="primary"
                      >
                        Show Hidden Products
                      </Radio.Button>
                    </Radio.Group>
                  )}
                  <Radio.Group defaultValue="a">
                    <Radio.Button
                      value="a"
                      onClick={showAddProductModal}
                      type="primary"
                    >
                      Add Product
                    </Radio.Button>
                    <AddProductForm
                      visible={isAddProductModalVisible}
                      onClose={hideAddProductModal}
                    />
                  </Radio.Group>
                </React.Fragment>
              }
            ></Card>
          </Col>
        </Row>
      </div>

      <Row gutter={[24, 24]}>
        {product?.map((product) => {
          const firstPhotoUrl =
            product.productPhotos && product.productPhotos.length > 0
              ? product.productPhotos[0].photoName
              : "";
          return (
            <Col key={product?.productId} xs={24} sm={12} md={8} lg={6}>
              <Card
                style={{ width: "100%", overflow: "hidden" }}
                cover={
                  <div className="h-[180px] overflow-hidden bg-cover justify-center items-center flex w-full">
                    <img
                      alt="productThumbnailImage"
                      src={firstPhotoUrl}
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                }
                actions={
                  isShowingHiddenProducts
                    ? [
                        <Button
                          key="unhide"
                          onClick={() => handleProductUnhide(product)}
                        >
                          UNHIDE
                        </Button>,
                      ]
                    : [
                        <Tooltip title="Edit">
                          <EditOutlined
                            style={{ color: "green" }}
                            key="edit"
                            onClick={() => showUpdateProductModal(product)}
                          />
                        </Tooltip>,
                        <Tooltip title="Upload Image">
                          <UploadOutlined
                            style={{ color: "black" }}
                            key="upload"
                            onClick={() => showAddImageModal(product)}
                          />
                        </Tooltip>,
                        <Tooltip title="Hide">
                          <EyeInvisibleOutlined
                            style={{ color: "darkBlue" }}
                            key="hide"
                            onClick={() => handleProductHide(product)}
                          />
                        </Tooltip>,
                      ]
                }
              >
                <Meta
                  title={product?.productName}
                  description={product?.description}
                />
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* UpdateProductForm */}
      {selectedProduct && (
        <>
          <UpdateProductForm
            visible={isUpdateProductModalVisible}
            onClose={hideUpdateProductModal}
            product={selectedProduct}
            onUpdate={(updatedProduct) => {
              setProduct((prevProducts) =>
                prevProducts.map((p) =>
                  p.productId === updatedProduct.productId ? updatedProduct : p
                )
              );
              hideUpdateProductModal();
            }}
          />
          <AddProductImages
            visible={isAddImageModalVisible}
            onClose={hideAddImageModal}
            product={selectedProduct}
            productId={selectedProduct.productId}
            onUpdate={(updatedProduct) => {
              // Handle the updated product in your state or any other logic
              console.log("Product updated:", updatedProduct);
              hideAddImageModal();
            }}
          />
        </>
      )}
    </React.Fragment>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Products
    </span>
  );
};

export default Product;
