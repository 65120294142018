import { Table, Button, Modal } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import AddCouponForm from "../components/AddCouponForm";
import { BASE_URL } from "../constants/Constants";
import UpdateCouponForm from "../components/UpdateCoupon";
// import { updateCoupon } from "../constants/Constants";
// import { deleteCoupon } from "../constants/Constants";
const Coupon = () => {
  const userId = localStorage.getItem("userId");
  const [coupons, setCoupons] = useState([]);
  const [isAddCouponModalVisible, setIsAddCouponModalVisible] = useState(false);
  const [isEditCouponModalVisible, setIsEditCouponModalVisible] =
    useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const showAddCouponModal = () => {
    setIsAddCouponModalVisible(true);
  };

  const hideAddCouponModal = () => {
    setIsAddCouponModalVisible(false);
  };

  const showEditCouponModal = (coupon) => {
    setSelectedCoupon(coupon);
    setIsEditCouponModalVisible(true);
  };

  const hideEditCouponModal = () => {
    setSelectedCoupon(null);
    setIsEditCouponModalVisible(false);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/coupons`);
      const items = response?.data;
      setCoupons(items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateCoupon = async (updatedCoupon, id) => {
    console.log(updatedCoupon);
    console.log(id);
    try {
      await axios.put(`${BASE_URL}/api/coupons/${id}`, updatedCoupon);
      setCoupons(
        coupons.map((coupon) =>
          coupon.couponId === id ? updatedCoupon : coupon
        )
      );
      hideEditCouponModal();
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCoupon = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/coupons/${id}`);

      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteConfirm = (coupon) => {
    Modal.confirm({
      title: "Are you sure you want to delete this coupon?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteCoupon(coupon.couponId);
      },
    });
  };

  const columns = [
    {
      title: "Coupon Name",
      dataIndex: "couponName",
      key: "couponName",
    },
    {
      title: "Coupon Type",
      dataIndex: "couponType",
      key: "couponType",
    },
    {
      title: "Cost",
      dataIndex: "couponValue",
      key: "couponValue",
    },
    {
      title: "Status",
      dataIndex: "couponStatus",
      key: "couponStatus",
      render: (couponStatus) => (
        <span style={{ color: couponStatus == "active" ? "green" : "red" }}>
          {couponStatus == "active" ? "Active" : "Inactive"}
        </span>
      ),
      filters: [
        { text: "Active", value: "active" },
        { text: "InActive", value: "inactive" },
      ],
      onFilter: (value, record) => record.couponStatus === value,
    },
    {
      title: "Edit",
      key: "edit",
      render: (text, record) => (
        <Button onClick={() => showEditCouponModal(record)}>Edit</Button>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => (
        <Button onClick={() => showDeleteConfirm(record)}>Delete</Button>
      ),
    },
  ];

  return userId ? (
    <>
      <Button
        type="primary"
        className="bg-blue-400"
        onClick={showAddCouponModal}
      >
        Add Coupon
      </Button>
      <AddCouponForm
        visible={isAddCouponModalVisible}
        onClose={hideAddCouponModal}
      />
      <UpdateCouponForm
        visible={isEditCouponModalVisible}
        onClose={hideEditCouponModal}
        onSubmit={handleUpdateCoupon} // Pass the handleUpdateCoupon function as the onSubmit prop
        initialValues={selectedCoupon} // Pass the selectedCoupon object as the initialValues prop
      />
      <Table dataSource={coupons} columns={columns} />
    </>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Products
    </span>
  );
};

export default Coupon;
