import React, { useEffect, useState } from "react";
// import { Button, Modal, Space } from 'antd';
import { PlusOutlined, SortAscendingOutlined } from "@ant-design/icons";
// import Swal from "sweetalert2";

// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom"; // Import useHistory instead of useNavigate

import { Button, DatePicker, Input, Modal, Space, message } from "antd";
import axios from "axios";
import { UPDATE_USER } from "../constants/Constants";
import { GET_USER } from "../constants/Constants";
import { BASE_URL } from "../constants/Constants";
// import "./Modal.css";
const EditUser = ({ data }) => {
  // const navigate = useNavigate();
  const history = useHistory(); // Use useHistory hook for navigation

  const [userData, setuserData] = useState(data);
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState(userData?.parName || "");
  const [Id, setId] = useState(userData?.userId || "");
  const [phone1, setPhone1] = useState(userData?.mobile1 || "");
  const [phone2, setPhone2] = useState(userData?.mobile2 || "");
  const [userEmail, setUserEmail] = useState(userData?.email || "");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(userData?.acType || "USER");
  const [status, setStatus] = useState(userData?.active || "");
  const [addr1, setAddr1] = useState(userData?.address1 || "");
  const [addr2, setAddr2] = useState(userData?.address2 || "");
  const [allAddresses, setAllAddresses] = useState([]);
  const [address, setAddress] = useState({
    name: userData?.name || "",
    mobile: userData?.mobile || "",
    houseName: userData?.houseName || "",
    street: userData?.street || "",
    landMark: userData?.landMark || "",
    city: userData?.city || "",
    state: userData?.state || "",
    pinCode: userData?.pinCode || "",
    country: userData?.country || "",
    addrType: userData?.addrType || "",
    countryCode: userData?.countryCode || "",
  });

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  console.log(userData);

  const handleUpdate = () => {
    handleUserUpdate();
    handleAddressUpdate();
  };

  const handleUserUpdate = async () => {
    try {
      const updatedData = {
        parName: userName,
        Id,
        mobile1: phone1,
        mobile2: phone2,
        email: userEmail,
        active: status,
      };
      const userId = data.userId;
      console.log(userId);

      const res = await axios.post(
        `${BASE_URL}/users/updateUser/${userId}`,
        updatedData
      );

      await axios.get(`${BASE_URL}/users/userByID/${userId}`);

      // Show success notification or perform other actions if needed
      setuserData(updatedData);
      //   Swal.fire({
      //     position: "center",
      //     icon: "success",
      //     title: "Data updated",
      //     showConfirmButton: false,
      //     timer: 2500,
      //   });
      message.success("data updated ");
      // navigate("/admin");
      setTimeout(() => {
        // navigate('/');
        handleOk();
        history.push("/tables"); // Correct the typo to history.push
        window.location.reload();
      }, 2000);
      console.log("User updated successfully");
    } catch (error) {
      // Show error notification if update fails
      // message.error("Failed to update user");
      console.error("Error updating user:", error);
    }
  };

  const handleAddressUpdate = async () => {
    try {
      for (let address of allAddresses) {
        const addressData = {
          addrId: address.addrId,
          name: address.name,
          houseName: address.houseName,
          street: address.street,
          landMark: address.landMark,
          city: address.city,
          state: address.state,
          pinCode: address.pinCode,
          country: address.country,
          countryCode: address.countryCode,
          mobile: address.mobile,
          addrType: address.addrType,
        };
        console.log(addressData);
        const savedAddressRes = await axios.post(
          `${BASE_URL}/users/updateAddress/${userData.userId}`,
          addressData
        );

        console.log(savedAddressRes.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeAddress = (e, index) => {
    const { name, value } = e.target;
    setAllAddresses((prevAddresses) => {
      const newAddresses = [...prevAddresses];
      newAddresses[index] = { ...newAddresses[index], [name]: value };
      return newAddresses;
    });
  };
  //   modal useState
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${BASE_URL}/users/getAllAddresses/${userData.userId}`
      );

      setAllAddresses(res.data);
    };
    fetchData();
  }, []);

  const handleChangeStatus = (event) => {
    const selectedValue = event.target.value;
    let newStatus;

    // Set new status based on the selected option
    if (selectedValue === "active") {
      newStatus = 0; // Set status to 0 for "Active"
    } else if (selectedValue === "closed") {
      newStatus = 1; // Set status to 1 for "Closed"
    } else {
      // Handle other cases if needed
      newStatus = ""; // Set default status if none of the above options match
    }

    // Update the status state
    setStatus(newStatus);
  };
  return (
    <>
      <Space>
        <Button
          type="primary"
          size="small"
          className="bg-blue-500 hover:bg-blue-700 rounded"
          onClick={showModal}
        >
          View
        </Button>
      </Space>
      <Modal
        visible={open}
        title="User Details"
        onOk={handleOk}
        onCancel={handleCancel}
        // className="text-black"
        // style={{ color: '#ffff' }} // Adjust the width here
        width={1000}
        okButtonProps={{
          style: {
            color: "black",
            height: "auto",
            fontSize: "14px",
            maxHeight: "100vh",
          },
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        {/* Your content here */}
        <div className="text-black">
          {/* < > */}

          <div className="flex flex-col gap-9 bg-transparent">
            {/* <!-- Contact Form --> */}
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-none dark:bg-transparent">
              <form action="#">
                <div className=" p-6.5 gap-10 ">
                  {/* Row -1 */}
                  <div className="mb-3 flex gap-10">
                    <div className="flex">
                      <p className="text-[1.05rem] font-semibold		">Status :</p>
                      <p className="  text-green mx-2 border-x-2 px-2 text-center text-[1.05rem] ">
                        {userData.active == 0 ? "active" : "closed"}
                      </p>
                    </div>
                    <div className="flex">
                      <p className="text-[1.05rem] font-semibold		">User Id :</p>

                      <p className="  text-green mx-2 border-x-2 px-2 text-center text-[1.05rem] ">
                        {" "}
                        {Id}
                      </p>
                    </div>
                  </div>
                  <div className="mb-4.5 flex flex-col mb-2 gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black font-semibold	 dark:text-black">
                        User Name
                      </label>
                      <input
                        type="text"
                        defaultValue={userName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        // disabled
                        placeholder="Enter your first name"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                  </div>
                  {/* Row -2 */}

                  <div className="mb-4.5 flex flex-col  mb-2 gap-6 xl:flex-row">
                    <div className="mb-4.5 w-full">
                      <label className="mb-2.5 block text-black font-semibold	 dark:text-black">
                        Email
                      </label>
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        defaultValue={userEmail}
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                        }}
                        // disabled
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-4.5 w-full">
                      <label className="mb-2.5 block text-black font-semibold	 dark:text-black">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        placeholder="Mobile No"
                        defaultValue={phone1}
                        // disabled
                        onChange={(e) => {
                          setPhone1(e.target.value);
                        }}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-4.5 w-full">
                      <label className="mb-2.5 block text-black font-semibold	 dark:text-black">
                        Alternate Phone Number
                      </label>
                      <input
                        type="tel"
                        placeholder="Alternate Mobile No"
                        defaultValue={phone2}
                        // disabled
                        onChange={(e) => {
                          setPhone2(e.target.value);
                        }}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                  </div>
                  <div className="gap-20  mb-2 lg:flex">
                    <div className="mb-4.5 w-full">
                      <label className="mb-2.5 block text-black font-semibold	 dark:text-black">
                        {" "}
                        Status
                      </label>
                      <div className="relative z-20 w-full bg-transparent dark:bg-form-input">
                        <select
                          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary "
                          value={status} // Set value attribute to manage the selected value
                          onChange={handleChangeStatus}
                        >
                          <option value="">Select Status</option>

                          <option value="active">Active</option>
                          <option value="closed">Closed</option>
                        </select>
                        <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                          <svg
                            className="fill-current"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                fill=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* About you  */}
                  <div className="w-full  mb-2">
                    {" "}
                    <label className="mb-2.5 block text-black font-semibold	 dark:text-black">
                      {" "}
                      Address
                    </label>
                    {allAddresses.map((addr, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: "50px",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Address"
                          name="houseName"
                          defaultValue={addr.houseName}
                          onChange={(e) => handleChangeAddress(e, index)}
                          className=" rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        />
                        <input
                          type="text"
                          placeholder="Address"
                          name="street"
                          defaultValue={addr.street}
                          onChange={(e) => handleChangeAddress(e, index)}
                          className="rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        />
                        <input
                          type="text"
                          placeholder="Address"
                          name="city"
                          defaultValue={addr.city}
                          onChange={(e) => handleChangeAddress(e, index)}
                          className="rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        />
                        <input
                          type="text"
                          placeholder="Address"
                          name="state"
                          defaultValue={addr.state}
                          onChange={(e) => handleChangeAddress(e, index)}
                          className="rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        />
                        <input
                          type="text"
                          name="pinCode"
                          placeholder="Address"
                          defaultValue={addr.pinCode}
                          onChange={(e) => handleChangeAddress(e, index)}
                          className="rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        />
                        <input
                          type="text"
                          placeholder="Address"
                          name="country"
                          defaultValue={addr.country}
                          onChange={(e) => handleChangeAddress(e, index)}
                          className="rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        />
                      </div>
                    ))}
                  </div>

                  <div className=" flex gap-20 ">
                    <Button
                      type="primary"
                      size="small"
                      className="bg-blue-500 hover:bg-blue-700 rounded"
                      onClick={handleUpdate}
                    >
                      Save
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      className="bg-blue-500 hover:bg-blue-700 rounded"
                      onClick={handleOk}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* </> */}
        </div>
      </Modal>
    </>
  );
};

export default EditUser;
