import React, { useState, useEffect } from "react";
import { Table, Tag, Input, Space, Button, Spin, message } from "antd";
import _ from "lodash"; // Import lodash
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";
import ReturnRequestDetails from "../components/ReturnRequestDetails";

const ReturnRequests = () => {
  const userId = localStorage.getItem("userId");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await axios.get(`${BASE_URL}/api/return-request/all`);
      const returnData = res.data.map((item) => ({
        ...item,
        userId: item.order.userId,
        orderId: item.order.serialNo,
        productid: item.order.productid,
        productName: item.order.productName,
        orderAddress: item.order.orderAddress,
        finalOrderAmount: item.order.finalOrderAmount,
      }));
      setData(returnData);
      setIsLoading(false);
    };
    // Debounce the fetchData function to delay continuous updates
    const delayedFetchData = _.debounce(fetchData, 0); // Adjust delay time as needed (e.g., 500ms)

    delayedFetchData(); // Call the debounced function

    return () => {
      // Clear any pending debounce calls on unmount
      delayedFetchData.cancel();
    };
    // fetchData();
  }, []);
  // console.log("--->", data[0].firstname);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, columnTitle) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="p-4">
        <Input
          placeholder={`Search ${columnTitle}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className="mb-2"
        />
        <Space>
          <button
            onClick={() => handleReset(clearFilters)}
            className="bg-gray-200 text-gray-800 mr-2 rounded px-4 py-2"
          >
            Reset
          </button>
          <button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            className="rounded bg-primary px-4 py-2 text-black"
          >
            OK
          </button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <strong>{text}</strong>
      ) : (
        <span>{text}</span>
      ),
  });

  const handleReturnCredited = async (id) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/api/return-request/confirm/${id}`
      );
      if (res.status === 200) {
        message.success("Return credited successfully!");
        // Refresh data after successful update
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, refundConfirmed: true } : item
        );
        setData(updatedData);
      }
    } catch (error) {
      console.error("Error crediting return:", error);
      message.error("Failed to credit return. Please try again later.");
    }
  };

  const columns = [
    {
      title: "SR NO",
      dataIndex: "sr",
      defaultSortOrder: "descend",
      className: "bg-boxdark  p-2.5 text-black text-sm font-medium uppercase",
    },
    {
      title: "RETURN ID",
      dataIndex: "id",
      ...getColumnSearchProps("id", "id"),
      className: "bg-boxdark  p-2.5 text-black text font-medium ",
    },
    {
      title: "ORDER ID",
      dataIndex: "orderId",
      ...getColumnSearchProps("orderId", "orderId"),

      // defaultSortOrder: 'descend',
      // ...getColumnSearchProps('gender', 'Genders'),

      className: "bg-boxdark   text-black hover:bg-unset text-center p-2.5",
    },
    {
      title: "RETURN DATE",
      dataIndex: "returnInitiateDate",
      ...getColumnSearchProps("returnInitiateDate", "Return Date"),
      render: (text) => moment(text).format("YYYY-MM-DD"),
      className: "bg-boxdark  text-black  p-2.5 text-center",
    },

    {
      title: "USER ID",
      dataIndex: "userId",
      // ...getColumnSearchProps("role", "role"),
      className: "bg-boxdark  text-black  p-2.5 text-center",
    },

    {
      title: "UPDATE STATUS",
      dataIndex: "refundConfirmed", // Change dataIndex to refundConfirmed
      render: (refundConfirmed, record) => (
        <Button
          type="primary"
          size="small"
          className="bg-blue-500 hover:bg-blue-700 rounded"
          onClick={() => handleReturnCredited(record.id)}
          disabled={refundConfirmed} // Disable button if refundConfirmed is true
        >
          Return Credited
        </Button>
      ),
      className: "text-white bg-boxdark p-2.5 text-center",
    },
    ,
    {
      title: "DETAILS",
      dataIndex: "viewMore",
      render: (_, record) => <ReturnRequestDetails data={record} />,
      className: " text-w hite bg-boxdark p-2.5 text-center",
    },
  ];

  const handleViewMore = (record) => {
    // Implement logic to handle "View More" button click
    console.log("View More Clicked for:", record);
  };

  // Generate serial numbers and modify the data
  const dataWithSrNo = data.map((item, index) => ({
    ...item,
    key: (index + 1).toString(), // Assigning unique key for Ant Design Table
    sr: index + 1, // Adding the serial number
  }));

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  return userId ? (
    <div className="rounded-sm border border-stroke px-5 pt-6 pb-2.5 text-black shadow-default dark:border-strokedark dark:bg-boxdark dark:text-black sm:px-7.5 xl:pb-1">
      {isLoading ? (
        <Spin /> // Conditionally render Spin based on isLoading state
      ) : (
        <Table
          key={dataWithSrNo.length} // Adding a stable key
          className="text-black dark:text-black"
          columns={columns}
          dataSource={dataWithSrNo}
          onChange={onChange}
          scroll={{ x: true }}
        />
      )}
    </div>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Return Requests
    </span>
  );
};

export default ReturnRequests;
