import React, { useState } from "react";
import { Layout, Card, Form, Input, Button, Typography, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";

const { Title } = Typography;
const { Content } = Layout;

const SignUp = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const onFinish = async () => {
    try {
      // Adding user with email
      const emailResponse = await axios.post(
        `${BASE_URL}/users/addNewUserEmail?emailId=${email}&password=${password}&name=${name}&phoneNo=${phoneNo}`
      );
      console.log(emailResponse);

      const { errorMessage } = emailResponse;
      if (errorMessage) {
        message.error(errorMessage);
      } else {
        message.success("User added successfully!");
      }
    } catch (error) {
      console.error(error.response);
      message.error("Something went wrong!");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="layout-default h-[100vh] w-full ant-layout layout-sign-up">
        <Content className="flex m-auto justify-center p-auto w-full">
          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Register</h5>}
            bordered={false}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row-col"
            >
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "Please input your Phone!" },
                ]}
              >
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                />
              </Form.Item>
              <Form.Item
                name="phoneNo"
                rules={[
                  { required: true, message: "Please input your Phone!" },
                ]}
              >
                <Input
                  type="text"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  placeholder="Phone Number"
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: "100%", height: "30px" }}
                  type="primary"
                  size="small"
                  htmlType="submit"
                  className="bg-blue-500 hover:bg-blue-700 rounded"
                >
                  SIGN UP
                </Button>
              </Form.Item>
            </Form>
            <p className="font-semibold text-muted text-center">
              Already have an account?{" "}
              <Link to="/sign-in" className="font-bold text-dark">
                Sign In
              </Link>
            </p>
          </Card>
        </Content>
      </div>
    </>
  );
};

export default SignUp;
