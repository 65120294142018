// UpdateProductForm.js

import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Upload, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";

const AddProductImages = ({
  visible,
  onClose,
  product,
  productId,
  onUpdate,
}) => {
  const [thumbnailFileList, setThumbnailFileList] = useState([]);
  const [additionalFileList, setAdditionalFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [disableThumbnailUpload, setDisableThumbnailUpload] = useState(false);

  useEffect(() => {
    if (visible && product) {
      // Set form values when the modal is visible and a product is provided
      form.setFieldsValue({
        productName: product.productName,
      });

      // Check if productPhotos array is not empty to disable thumbnail upload
      if (product.productPhotos && product.productPhotos.length > 0) {
        setDisableThumbnailUpload(true);
      }
    }
  }, [visible, product, form]);

  const handleThumbnailFileChange = ({ fileList }) => {
    setThumbnailFileList(fileList.slice(-1)); // Only keep the last uploaded file
    console.log("thumbnail file", fileList);
  };

  const handleAdditionalFileChange = ({ fileList }) => {
    setAdditionalFileList(fileList);
    console.log("additional files", fileList);
  };

  const handleAddImages = async () => {
    setIsLoading(true);
    try {
      await handleAddThumbnailImage();
      await handleAddAdditionalImages();

      message.success("Photos uploaded successfully");

      onClose();
      window.location.reload();
    } catch (error) {
      message.error("Failed to add photos");
    } finally {
      setIsLoading(false);
    }
  };
  const handleAddThumbnailImage = async () => {
    try {
      const formData = new FormData();
      if (thumbnailFileList.length > 0) {
        formData.append("photos", thumbnailFileList[0].originFileObj);
      }

      console.log(formData);
      setIsLoading(true);

      const photoRes = await axios.post(
        `${BASE_URL}/productphotos/upload/${productId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(photoRes);

      // Close the modal
      onClose();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.log("error while adding thumbnail photo");
    }
  };

  const handleAddAdditionalImages = async () => {
    try {
      setIsLoading(true);

      // Determine the highest existing sequence number and start from the next one
      const existingPhotos = product.productPhotos || [];
      let maxSequence = 1; // Starting sequence number before adding new photos

      let existingPhotoCount = 0; // Count existing photos

      for (let i = 0; i < existingPhotos.length; i++) {
        if (existingPhotos[i].photoName2)
          maxSequence = Math.max(maxSequence, 2);
        if (existingPhotos[i].photoName3)
          maxSequence = Math.max(maxSequence, 3);
        if (existingPhotos[i].photoName4)
          maxSequence = Math.max(maxSequence, 4);
        if (existingPhotos[i].photoName5)
          maxSequence = Math.max(maxSequence, 5);
        if (existingPhotos[i].photoName6)
          maxSequence = Math.max(maxSequence, 6);
        if (existingPhotos[i].photoName7)
          maxSequence = Math.max(maxSequence, 7);
        if (existingPhotos[i].photoName8)
          maxSequence = Math.max(maxSequence, 8);
        if (existingPhotos[i].photoName9)
          maxSequence = Math.max(maxSequence, 9);
        if (existingPhotos[i].photoName10)
          maxSequence = Math.max(maxSequence, 10);
      }

      // Check if the total number of photos exceeds 10
      const totalNewPhotos = additionalFileList.length;
      if (existingPhotoCount + totalNewPhotos > 10) {
        message.warning("Cannot upload more than 10 additional photos");
        setIsLoading(false);
        return;
      }

      // Upload each additional image with an incremented sequence number
      for (let i = 0; i < additionalFileList.length; i++) {
        const formData = new FormData();
        formData.append("additionalPhoto", additionalFileList[i].originFileObj);

        const sequenceNo = maxSequence + 1 + i; // Increment sequence for each additional image
        if (sequenceNo > 10) {
          message.error("Cannot upload more than 10 additional photos");
          break;
        }

        const photoRes = await axios.put(
          `${BASE_URL}/productphotos/update?productId=${productId}&sequenceNo=${sequenceNo}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(photoRes);
      }

      // Close the modal
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Add Product Images"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="update"
          style={{
            border: "1px solid rgb(18, 127, 190)",
            color: "rgb(18, 127, 190)",
          }}
          onClick={handleAddImages}
        >
          {isLoading ? <Spin /> : "Add Images"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Product Name"
          name="productName"
          rules={[{ required: true, message: "Please enter the product name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Product Thumbnail Image"
          name="productThumbnail"
          rules={[
            {
              required: true,
              message: "Please enter the product thumbnail image",
            },
          ]}
        >
          <Upload
            onChange={handleThumbnailFileChange}
            showUploadList={true}
            beforeUpload={() => false}
            fileList={thumbnailFileList}
            disabled={disableThumbnailUpload}
          >
            <Button disabled={disableThumbnailUpload} icon={<UploadOutlined />}>
              Upload Image
            </Button>
          </Upload>

          {/* <Button icon={<UploadOutlined />} onClick={customRequest}>Submit</Button> */}
        </Form.Item>
        <Form.Item
          label="Product Additional Images (You can add multiple images here!)"
          name="productImages"
        >
          <Upload
            onChange={handleAdditionalFileChange}
            multiple
            showUploadList={true}
            beforeUpload={() => false}
            fileList={additionalFileList}
          >
            <Button icon={<UploadOutlined />}>Upload Images</Button>
          </Upload>

          {/* <Button icon={<UploadOutlined />} onClick={customRequest}>Submit</Button> */}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProductImages;
