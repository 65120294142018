// UpdateProductForm.js

import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Spin,
  Upload,
  Select,
  Space,
} from "antd";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";
import {
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const UpdateProductForm = ({ visible, onClose, product, onUpdate }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailFileList, setThumbnailFileList] = useState([]);
  const [additionalFileList, setAdditionalFileList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [sequenceNo, setSequenceNo] = useState(null);

  const handleThumbnailFileChange = ({ fileList }) => {
    setThumbnailFileList(fileList.slice(-1)); // Only keep the last uploaded file
    console.log("thumbnail file", fileList);
  };

  const handleAdditionalFileChange = ({ fileList }) => {
    setAdditionalFileList(fileList);
  };

  useEffect(() => {
    if (visible && product) {
      // Fetch categories and subcategories
      fetchCategoryData().then(() => {
        const matchedCategory = categories.find(
          (category) => category.categoryId === product.categoryId
        );

        if (matchedCategory) {
          setSelectedCategory(matchedCategory.categoryId);
          setSubcategories(matchedCategory.subcategoryList);
        }

        // Set form values when the modal is visible and a product is provided
        form.setFieldsValue({
          productName: product.productName,
          prdDesc: product.prdDesc,
          prdDesc2: product.prdDesc2?.split("\n"),
          categoryId: product.categoryId,
          subcategoryId: product.subcategoryId,
          purRate: product.purRate,
          mrp: product.mrp,
          weight: product.weight,
          productQuantity: product.productQuantity,
          minStk: product.minStk,
        });

        // Initialize the file list with the first product photo
        if (product.productPhotos && product.productPhotos.length > 0) {
          setThumbnailFileList([
            {
              uid: "-1",
              name: "current_image.png",
              status: "done",
              url: product.productPhotos[0].photoName,
            },
          ]);

          const additionalPhotos = product.productPhotos
            .map((photo, index) => {
              const photoList = [];
              for (let i = 2; i <= 10; i++) {
                const photoName = photo[`photoName${i}`];
                if (photoName) {
                  photoList.push({
                    uid: `${index}-${i}`,
                    name: `additional_image_${index + 1}_${i}.png`,
                    status: "done",
                    url: photoName,
                  });
                }
              }
              return photoList;
            })
            .flat();

          setAdditionalFileList(additionalPhotos);
        } else {
          setThumbnailFileList([]);
          setAdditionalFileList([]);
        }
      });
    }
  }, [visible, product]);

  const handleUpdateProduct = async () => {
    try {
      const values = await form.validateFields();
      const productId = product.productId;
      setIsLoading(true);

      values.prdDesc2 = values.prdDesc2.join("\n");

      // If a new thumbnail file is uploaded, update the photo
      if (thumbnailFileList.length > 0 && thumbnailFileList[0].originFileObj) {
        const formData = new FormData();
        formData.append("additionalPhoto", thumbnailFileList[0].originFileObj);

        await axios.put(
          `${BASE_URL}/productphotos/update?productId=${productId}&sequenceNo=1`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      // If new additional images are uploaded, update the photos
      if (additionalFileList.length > 0) {
        for (let i = 0; i < additionalFileList.length; i++) {
          if (additionalFileList[i].originFileObj) {
            const formData = new FormData();
            formData.append(
              "additionalPhoto",
              additionalFileList[i].originFileObj
            );

            await axios.put(
              `${BASE_URL}/productphotos/update?productId=${productId}&sequenceNo=${sequenceNo}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }
        }
      }

      // Make API request to update product
      const response = await axios.put(
        `${BASE_URL}/products/update/${productId}`,
        values
      );

      // Handle success, e.g., show a success message
      message.success("Product updated successfully");

      // Close the modal
      onClose();

      // window.location.reload();

      // Trigger the onUpdate callback to update the product in the parent component
      // onUpdate(response.data); // Assuming the API returns the updated product data
    } catch (error) {
      // Handle errors, e.g., show an error message
      message.error("Failed to update product");
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  const fetchCategoryData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/category/all-unhide`);
      const items = response?.data;
      setCategories(items);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (categoryId) => {
    const matchedCategory = categories.find(
      (category) => category.categoryId === categoryId
    );
    setSelectedCategory(categoryId);
    if (matchedCategory) {
      setSubcategories(matchedCategory.subcategoryList);
      form.setFieldsValue({ subcategoryId: null });
    } else {
      setSubcategories([]);
    }
  };

  const handleSequenceNoChange = (e) => {
    setSequenceNo(e.target.value);
  };

  return (
    <Modal
      title="Update Product"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="update"
          style={{
            border: "1px solid rgb(18, 127, 190)",
            color: "rgb(18, 127, 190)",
          }}
          onClick={handleUpdateProduct}
        >
          {isLoading ? <Spin /> : "Update Product"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Product Name" name="productName">
          <Input />
        </Form.Item>

        <Form.Item label="Short Description" name="prdDesc">
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Detailed Description" name="prdDesc2">
          <Form.List name="prdDesc2">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={name}
                      fieldKey={fieldKey}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a description point",
                        },
                      ]}
                    >
                      <Input placeholder="Enter a description point" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Point
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item label="Product Categories" name="categoryId">
          <Select
            placeholder="Select a Category"
            onChange={handleCategoryChange}
            value={selectedCategory}
          >
            {categories.map((category) => (
              <Option key={category.categoryId} value={category.categoryId}>
                {category.categoryName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Subcategories" name="subcategoryId">
          <Select
            placeholder="Select a Sub-Category"
            onChange={(value) => form.setFieldsValue({ subcategoryId: value })}
            value={form.getFieldValue("subcategoryId")}
          >
            {subcategories.map((subcategory) => (
              <Option
                key={subcategory.subCategoryId}
                value={subcategory.subCategoryId}
              >
                {subcategory?.subCategoryName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Purchase Price" name="purRate">
          <Input />
        </Form.Item>

        <Form.Item label="MRP Price" name="mrp">
          <Input />
        </Form.Item>

        <Form.Item label="Product Weight" name="weight">
          <Input />
        </Form.Item>

        <Form.Item label="Product Quantity (per pack)" name="productQuantity">
          <Input />
        </Form.Item>

        <Form.Item label="Stock Quantity" name="minStk">
          <Input />
        </Form.Item>

        <Form.Item label="Update Product Thumbnail" name="productImages">
          <Upload
            onChange={handleThumbnailFileChange}
            showUploadList={{ showRemoveIcon: false }}
            beforeUpload={() => false}
            fileList={thumbnailFileList}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>

          {/* <Button icon={<UploadOutlined />} onClick={customRequest}>Submit</Button> */}
        </Form.Item>
        <Form.Item
          label="Update Additional Images (Note: Sequence No for photo should be number between 2-10)"
          name="additionalImages"
        >
          {" "}
          <Input
            className="mb-2"
            placeholder="Enter photo sequence No (2-10)"
            onChange={handleSequenceNoChange}
          />
          <Upload
            onChange={handleAdditionalFileChange}
            showUploadList={{ showRemoveIcon: false }}
            beforeUpload={() => false}
            fileList={additionalFileList}
            listType="picture"
            multiple
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
          {/* <Button icon={<UploadOutlined />} onClick={customRequest}>Submit</Button> */}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateProductForm;
