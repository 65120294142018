import React, { useState } from "react";
import { Modal, Form, Input, Button, message, Upload, Spin } from "antd";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";
import { UploadOutlined } from "@ant-design/icons";

const AddCategoryForm = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailFileList, setThumbnailFileList] = useState([]);

  const handleThumbnailFileChange = ({ fileList }) => {
    setThumbnailFileList(fileList.slice(-1)); // Only keep the last uploaded file
    console.log("thumbnail file", fileList);
  };

  const handleAddCategory = async () => {
    try {
      const values = await form.validateFields();

      const { categoryName } = values;

      setIsLoading(true);

      // Prepare form data
      const formData = new FormData();
      if (thumbnailFileList.length > 0) {
        formData.append("categoryPhoto", thumbnailFileList[0].originFileObj);
      }

      // Make API request to add product
      const response = await axios.post(
        `${BASE_URL}/category/addCategory?categoryName=${categoryName}`,
        formData
      );

      // Handle success, e.g., show a success message
      message.success("Category added successfully");
      form.resetFields();
      window.location.reload();
      // Close the modal
      onClose();
    } catch (error) {
      // Handle errors, e.g., show an error message
      message.error("Failed to add category");
    }
  };

  return (
    <Modal
      title="Add Category"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="add"
          style={{
            border: "1px solid rgb(69, 172, 207)",
            color: "rgb(69, 172, 207)",
          }}
          onClick={handleAddCategory}
        >
          {isLoading ? <Spin style={{ color: "white" }} /> : "Add Category"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Category Name"
          name="categoryName"
          rules={[
            { required: true, message: "Please enter the category name" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Category Thumbnail Image"
          name="categoryThumbnail"
          rules={[
            { required: true, message: "Please enter the category name" },
          ]}
        >
          <Upload
            onChange={handleThumbnailFileChange}
            showUploadList={true}
            beforeUpload={() => false}
            fileList={thumbnailFileList}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCategoryForm;
