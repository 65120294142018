import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Upload, Spin } from "antd";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";
import { UploadOutlined } from "@ant-design/icons";

const UpdateCategoryForm = ({ visible, onClose, category, onUpdate }) => {
  const [form] = Form.useForm();
  const [thumbnailFileList, setThumbnailFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (visible && category) {
      // Set form values when the modal is visible and a product is provided
      form.setFieldsValue({
        categoryName: category.categoryName,
      });

      // Initialize the file list with the current category image
      if (category.categoryImage) {
        setThumbnailFileList([
          {
            uid: "-1",
            name: "current_image.png",
            status: "done",
            url: category.categoryImage, // Use the URL from the response
          },
        ]);
      } else {
        setThumbnailFileList([]);
      }
    }
  }, [visible, category, form]);

  const handleThumbnailFileChange = ({ fileList }) => {
    setThumbnailFileList(fileList.slice(-1)); // Only keep the last uploaded file
    console.log("thumbnail file", fileList);
  };

  const handleUpdateCategory = async () => {
    try {
      const values = await form.validateFields();
      const categoryId = category.categoryId;
      console.log("Category ID:", categoryId);

      setIsLoading(true);

      // Log the values being sent to the API
      console.log("Updating category with values:", values);

      // Prepare form data
      const formData = new FormData();
      formData.append("categoryName", values.categoryName);
      formData.append("categoryId", categoryId);
      if (thumbnailFileList.length > 0) {
        formData.append("categoryPhoto", thumbnailFileList[0].originFileObj);
      }

      // Make API request to update category
      const response = await axios.put(
        `${BASE_URL}/category/updateCategory`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success("Category Updated successfully");
      // window.location.reload();

      onClose();

      // onUpdate(response.data);
      // Log the response received from the API
    } catch (error) {
      console.error("Failed to update Category:", error);

      // Handle errors, e.g., show an error message
      message.error("Failed to update Category");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Update Category"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="update" onClick={handleUpdateCategory}>
          {isLoading ? <Spin /> : "Update Category"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ categoryName: category ? category.categoryName : "" }}
      >
        <Form.Item
          label="Category Name"
          name="categoryName"
          rules={[
            { required: true, message: "Please enter the category name" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Category Thumbnail Image" name="categoryThumbnail">
          <Upload
            onChange={handleThumbnailFileChange}
            showUploadList={true}
            beforeUpload={() => false}
            fileList={thumbnailFileList}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>

          {/* <Button icon={<UploadOutlined />} onClick={customRequest}>Submit</Button> */}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateCategoryForm;
