import React, { useState } from "react";
import { Layout, Card, Form, Input, Button, Typography, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";
import Swal from "sweetalert2";

const { Content } = Layout;

const SignIn = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onFinish = async () => {
    try {
      const userType = "ADMIN";
      const response = await axios.post(
        `${BASE_URL}/users/loginUser?emailId=${email}&password=${password}&userType=${userType}`
      );

      const { userId } = response.data;

      if (!userId) {
        message.error(
          "User not found. Please check your credentials or sign up."
        );

        return;
      }

      localStorage.setItem("userId", userId);

      console.log("Login response:", response.data);

      Swal.fire("Success!", "You have signed in successfully.", "success");
      // navigate('/')
      history.push("/dashboard");

      console.log("Navigating to dashboard"); // Debug statement

      // Handle success response from the server
    } catch (error) {
      message.error("Invalid Credentials");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="layout-default h-[100vh] w-full ant-layout layout-sign-up">
        <Content className="flex m-auto justify-center p-auto w-full">
          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Login</h5>}
            bordered={false}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinishFailed={onFinishFailed}
              className="row-col"
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: "100%", height: "30px" }}
                  type="primary"
                  size="small"
                  htmlType="submit"
                  onClick={onFinish}
                  className="bg-blue-500 hover:bg-blue-700 rounded"
                >
                  SIGN IN
                </Button>
              </Form.Item>
            </Form>
            <p className="font-semibold text-muted text-center">
              Create an account?{" "}
              <Link to="/sign-up" className="font-bold text-dark">
                Sign Up
              </Link>
            </p>
          </Card>
        </Content>
      </div>
    </>
  );
};

export default SignIn;
