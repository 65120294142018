import React, { useState, useEffect } from "react";
import { Table, Tag, Button, Space, Modal, DatePicker } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import "antd/dist/antd.css";
import "tailwindcss/tailwind.css";
import "jspdf-autotable";
import OrderDetailsPage from "./orderDetails/OrderDetails";
import { BASE_URL } from "../../constants/Constants";
import axios from "axios";

const OrderTable = () => {
  const userId = localStorage.getItem("userId");
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]); // Refetch data when startDate or endDate changes

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/orders/allOrdersList?size=1000000`
      );
      const data = response.data;
      const ordersData = data.content;

      // Sort orders by creation date in descending order
      const sortedOrders = ordersData.sort(
        (a, b) => new Date(b.billDate) - new Date(a.billDate)
      );

      setOrders(sortedOrders);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const applyFilters = () => {
    // Filter orders based on the selected start date and end date
    const filteredOrders = orders.filter((order) => {
      const orderDate = moment(order.billDate);
      return (
        orderDate.isSameOrAfter(startDate, "day") &&
        orderDate.isSameOrBefore(endDate, "day")
      );
    });

    console.log("Filtered Orders:", filteredOrders);
    setOrders(filteredOrders);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    fetchData();
  };

  const showOrderDetailsModal = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
  };

  const orderStatusColorMap = {
    ORDERED: "blue",
    SHIPPED: "purple",
    DELIVERED: "green",
    CANCELLED: "red",
  };

  const paymentStatusColorMap = {
    PAID: "green",
    PENDING: "orange",
    FAILED: "red",
  };

  const downloadPDF = () => {
    const pdf = new jsPDF();

    // Calculate minimum and maximum dates
    const dates = orders.map((item) => moment(item.billDate));
    const minDate = moment.min(dates).format("DD-MMM-YYYY");
    const maxDate = moment.max(dates).format("DD-MMM-YYYY");

    // Add the heading and dates
    pdf.setFontSize(16);
    pdf.text("Divine Depot - Orders Summary", 20, 20);
    pdf.setFontSize(12);
    pdf.text(`From: ${minDate} To: ${maxDate}`, 20, 30);

    // Filter orders based on the date range
    const filteredOrders = orders.filter(
      (order) =>
        (!startDate ||
          moment(order.billDate).isSameOrAfter(startDate, "day")) &&
        (!endDate || moment(order.billDate).isSameOrBefore(endDate, "day"))
    );

    console.log("Filtered orders", filteredOrders);

    const rows = filteredOrders.map((order) => [
      order.billNo,
      order.productid,
      moment(order.billDate).format("DD-MMM-YYYY h:mm A"),
      order.paymentStatus,
      `Rs ${order.finalOrderAmount.toFixed(2)}`,
      order.paymentMethod,
      order.orderStatus,
    ]);

    pdf.autoTable({
      startY: 40,
      head: [
        [
          "Order ID",
          "Products",
          "Date",
          "Payment Status",
          "Total Amount",
          "Payment Method",
          "Order Status",
        ],
      ],
      body: rows,
    });

    const totalAmount = filteredOrders.reduce(
      (total, order) => total + order.finalOrderAmount,
      0
    );
    pdf.text(
      `Total Amount: Rs ${totalAmount.toFixed(2)}`,
      20,
      pdf.autoTable.previous.finalY + 10
    );

    pdf.save("order_summary.pdf");
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "serialNo",
      key: "serialNo",
      sorter: (a, b) => {
        const billNoA = a.billNo ? String(a.billNo) : "";
        const billNoB = b.billNo ? String(b.billNo) : "";
        return billNoA.localeCompare(billNoB);
      },
      render: (orderId, record) => (
        <span
          className="text-sm"
          onClick={() => showOrderDetailsModal(record)}
          style={{ cursor: "pointer" }}
        >
          {String(orderId)}
        </span>
      ),
    },
    {
      title: "Products",
      dataIndex: "productid",
      key: "productid",
      render: (productId) => <span className="text-sm">{productId}</span>,
    },
    {
      title: "Date",
      dataIndex: "billDate",
      key: "billDate",
      render: (createdAt) => (
        <div>
          <div className="text-sm">
            {moment(createdAt).format("DD-MMM-YYYY")}
          </div>
          <div className="text-xs">{moment(createdAt).format("h:mm A")}</div>
        </div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      filters: [
        { text: "PAID", value: "PAID" },
        { text: "PENDING", value: "PENDING" },
        { text: "FAILED", value: "fAILED" },
      ],
      onFilter: (value, record) => record.paymentStatus === value,
      render: (status) => (
        <Tag
          color={paymentStatusColorMap[status]}
          style={{ fontWeight: "bold", fontSize: "12px" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "finalOrderAmount",
      key: "finalOrderAmount",
      render: (finalOrderAmount) => (
        <span className="text-lg font-semibold text-black">
          ₹ {finalOrderAmount.toFixed(2)}
        </span>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      filters: [
        { text: "ORDERED", value: "ORDERED" },
        { text: "SHIPPED", value: "SHIPPED" },
        { text: "DELIVERED", value: "DELIVERED" },
        { text: "CANCELLED", value: "CANCELED" },
      ],
      onFilter: (value, record) => record.orderStatus === value,
      render: (status) => (
        <Tag
          color={orderStatusColorMap[status]}
          style={{ fontWeight: "bold", fontSize: "12px" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            size="small"
            className="bg-blue-500 hover:bg-blue-700 rounded"
            onClick={() => showOrderDetailsModal(record)}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  return userId ? (
    <>
      <div className="my-5 float-right">
        <DatePicker placeholder="Start Date" onChange={handleStartDateChange} />
        <DatePicker
          placeholder="End Date"
          onChange={handleEndDateChange}
          style={{ marginLeft: 10 }}
        />
        <Button
          type="primary"
          onClick={applyFilters}
          className="bg-blue-500"
          style={{ marginLeft: 10 }}
        >
          Apply Filters
        </Button>
        <Button
          type="primary"
          className="bg-blue-500"
          onClick={downloadPDF}
          style={{ marginLeft: 10 }}
        >
          Download PDF
        </Button>
        <Button
          type="default"
          className="bg-blue-500 text-white"
          onClick={handleResetFilters}
          style={{ marginLeft: 10 }}
        >
          Reset Filters
        </Button>
      </div>

      {/* Conditionally render Table component only when orders are available */}
      {orders.length > 0 && (
        <Table
          dataSource={orders}
          columns={columns}
          rowKey={(record) => record._id}
          pagination={{ pageSize: 10 }}
          bordered
          scroll={{ x: true }}
        />
      )}

      {/* Modal for displaying order details */}
      <Modal
        title="Order Details"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
      >
        {selectedOrder && <OrderDetailsPage order={selectedOrder} />}
      </Modal>
    </>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Order Details
    </span>
  );
};

export default OrderTable;
