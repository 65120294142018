import React from "react";
import { Row, Col, Card, Menu, Dropdown, Button } from "antd";
import {
  DollarCircleOutlined,
  TransactionOutlined,
  CheckCircleOutlined,
  CarOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useToaster } from "react-hot-toast";
import { BASE_URL } from "../../../constants/Constants";
import axios from "axios";

const { Meta } = Card;

const OrderInfo = ({ order }) => {
  const {
    paymentMethod,
    transactionId,
    paymentStatus,
    billNo,
    serialNo,
    createdAt,
  } = order;

  const handleMenuClick = async ({ key }) => {
    try {
      console.log(order.serialNo);
      const response = await axios.put(
        `${BASE_URL}/orders/payment-status/${serialNo}?newStatus=${key}`
      );

      if (response.ok) {
        if (response.status === 200) {
          console.log(response.message);
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } else {
        console.error("Failed to update order status:", response.statusText);
      }
      window.location.reload();
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="PENDING">PENDING</Menu.Item>
      <Menu.Item key="PAID">PAID</Menu.Item>
      <Menu.Item key="FAILED">FAILED</Menu.Item>
    </Menu>
  );

  return (
    <div className="my-6">
      <Row gutter={[16, 16]}>
        {/* Billing Information Box */}
        <Col xs={24} md={12}>
          <Card
            title="Billing Information"
            bordered={false}
            className="w-full bg-slate-200"
            // style={{ width: '100%', background: '#f0f4f7' }}
            actions={[
              <DollarCircleOutlined key="paymentOption" />,
              <TransactionOutlined key="transactionId" />,
              <CheckCircleOutlined key="paymentStatus" />,
            ]}
          >
            <Meta title={`Payment Option: ${paymentMethod}`} />
            <Meta title={`Transaction ID: ${transactionId}`} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Meta title={`Payment Status: ${paymentStatus}`} />
              <div className="bg-slate-400 h-8 text-white ">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Button
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Update Status <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </Card>
        </Col>

        {/* Delivery Information Box */}
        <Col xs={24} md={12}>
          <Card
            title="Delivery Information"
            bordered={false}
            className="w-full bg-slate-200"
            // style={{ width: '100%', background: '#f0f4f7' }}
            actions={[
              <CarOutlined key="truckIcon" style={{ fontSize: "30px" }} />,
            ]}
          >
            <Meta
              title={`Order ID : ${billNo}`}
              description={`Type of Delivery: ${order.paymentMethod}`}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OrderInfo;
