import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BASE_URL } from "../constants/Constants";
import axios from "axios";
import { Table, Input, Button, Spin } from "antd";

const ProductDetails = () => {
  const userId = localStorage.getItem("userId");
  const [product, setProduct] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/products/allProductList`);
      const items = response?.data;

      const response2 = await axios.get(
        `${BASE_URL}/products/allHiddenProductList`
      );

      const items2 = response2?.data;

      // Combine and sort products by createdDate
      const combinedItems = [...items, ...items2].sort(
        (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
      );

      setProduct(combinedItems);
      setFilteredProducts(combinedItems);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = product.filter((product) =>
      product.productName.toLowerCase().includes(value)
    );
    setFilteredProducts(filtered);
  };

  const columns = [
    {
      title: "ProductId",
      dataIndex: "productId",
      key: "productId",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Purchase price",
      dataIndex: "purRate",
      key: "purRate",
      sorter: (a, b) => a.purRate - b.purRate,
    },
    {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
      sorter: (a, b) => a.mrp - b.mrp,
    },
    {
      title: "In Stock",
      dataIndex: "productQuantity",
      key: "productQuantity",
      sorter: (a, b) => a.productQuantity - b.productQuantity,
    },
    {
      title: "Active",
      dataIndex: "productHidden",
      key: "productHidden",
      render: (productHidden) => (
        <span style={{ color: productHidden ? "red" : "green" }}>
          {productHidden ? "Inactive" : "active"}
        </span>
      ),
      filters: [
        { text: "Active", value: false },
        { text: "InActive", value: true },
      ],
      onFilter: (value, record) => record.productHidden === value,
    },
  ];

  return userId ? (
    <div>
      {" "}
      <Input
        placeholder="Search Product by Name"
        value={searchText}
        onChange={handleSearch}
        style={{ width: 200, marginBottom: 16, border: "1px solid #1890ff" }}
      />
      <Table
        columns={columns}
        dataSource={filteredProducts}
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
    </div>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Products Details
    </span>
  );
};

export default ProductDetails;
