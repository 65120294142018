import { Row, Col, Card, Radio, Button, Typography } from "antd";

import { EyeInvisibleOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";

import { useEffect, useState } from "react";
import AddCategoryForm from "../components/AddCategoryForm";
import UpdateCategoryForm from "../components/UpdateCategoryForm";
import { BASE_URL } from "../constants/Constants";

const { Meta } = Card;
const { Title } = Typography;

const Category = () => {
  const userId = localStorage.getItem("userId");
  const [category, setCategory] = useState([]);
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] =
    useState(false);
  const [isUpdateCategoryModalVisible, setIsUpdateCategoryModalVisible] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isShowingHiddenCategory, setIsShowingHiddenCategory] = useState(false);

  const showAddCategoryModal = () => {
    setIsAddCategoryModalVisible(true);
  };

  const hideAddCategoryModal = () => {
    setIsAddCategoryModalVisible(false);
  };

  const showUpdateCategoryModal = (category) => {
    setSelectedCategory(category);
    setIsUpdateCategoryModalVisible(true);
  };

  const hideUpdateCategoryModal = () => {
    setSelectedCategory(null);
    setIsUpdateCategoryModalVisible(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsShowingHiddenCategory(false);
      const response = await axios.get(`${BASE_URL}/category/all-unhide`);
      const items = response?.data;
      setCategory(items);
      console.log(items);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHiddenCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/category/all-hide`);
      const hiddenItems = response?.data;

      setCategory(hiddenItems);
      setIsShowingHiddenCategory(true);
      console.log(hiddenItems);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleCategoryUnHide = async (categoryId) => {
    try {
      console.log(category);

      const response = await axios.post(
        `${BASE_URL}/category/unhide?categoryId=${categoryId}`
      );
      fetchData();

      console.log("Product Deleted Successfully");
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleCategoryHide = async (categoryId) => {
    try {
      console.log(category);

      const response = await axios.post(
        `${BASE_URL}/category/hide?categoryId=${categoryId}`
      );
      fetchData();

      console.log("Product Deleted Successfully");
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  return userId ? (
    <>
      <div>
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                isShowingHiddenCategory ? "Hidden Categories" : "Category Data"
              }
              extra={
                <>
                  <Radio.Group defaultValue="a">
                    {isShowingHiddenCategory ? (
                      <Radio.Button
                        value="a"
                        type="primary"
                        className="mr-4"
                        onClick={fetchData}
                      >
                        All Categories
                      </Radio.Button>
                    ) : (
                      <Radio.Button
                        value="a"
                        type="primary"
                        className="mr-4"
                        onClick={fetchHiddenCategories}
                      >
                        Show Hidden Categories
                      </Radio.Button>
                    )}
                    <Radio.Button
                      value="a"
                      onClick={showAddCategoryModal}
                      type="primary"
                    >
                      Add Category
                    </Radio.Button>

                    <AddCategoryForm
                      visible={isAddCategoryModalVisible}
                      onClose={hideAddCategoryModal}
                    />
                  </Radio.Group>
                </>
              }
            ></Card>
          </Col>
        </Row>
      </div>

      <Row gutter={[24, 24]}>
        {category.map((category) => (
          <Col key={category.categoryId} xs={24} sm={12} md={8} lg={6}>
            <Card
              style={{ width: "100%" }}
              actions={
                isShowingHiddenCategory
                  ? [
                      <Button
                        key="unhide"
                        onClick={() =>
                          handleCategoryUnHide(category.categoryId)
                        }
                      >
                        UNHIDE
                      </Button>,
                    ]
                  : [
                      // <SettingOutlined key="setting" />,
                      <EditOutlined
                        style={{ color: "green" }}
                        key="edit"
                        onClick={() => showUpdateCategoryModal(category)}
                      />,
                      <EyeInvisibleOutlined
                        style={{ color: "red" }}
                        key="delete"
                        onClick={() => handleCategoryHide(category.categoryId)}
                      />,
                    ]
              }
            >
              <Meta title={category?.categoryName} />
            </Card>
          </Col>
        ))}
      </Row>

      {/* UpdateProductForm */}
      {selectedCategory && (
        <UpdateCategoryForm
          visible={isUpdateCategoryModalVisible}
          onClose={hideUpdateCategoryModal}
          category={selectedCategory}
          onUpdate={(updatedCategory) => {
            // Handle the updated product in your state or any other logic
            console.log("Category updated:", updatedCategory);

            hideUpdateCategoryModal();
          }}
        />
      )}
    </>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Categories
    </span>
  );
};

export default Category;
