import React, { useState, useEffect } from "react";
import { Table, Button, Modal, List, Card } from "antd";
import axios from "axios";
import { BASE_URL } from "../constants/Constants";

const Carts = () => {
  const userId = localStorage.getItem("userId");
  const [userIds, setUserIds] = useState([]);
  const [visible, setVisible] = useState(false);
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    fetchUserIds();
  }, []);

  const fetchUserIds = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/usercart/distinct-user-ids`
      );
      setUserIds(response.data.content);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchCartData = async (userId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/usercart/in-cart?userId=${userId}`
      );
      const cartItems = response.data;

      setCartData(cartItems);
      setVisible(true);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "username",
    },

    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          className="bg-blue-600"
          onClick={() => fetchCartData(record.userId)}
        >
          View Carts
        </Button>
      ),
    },
  ];

  const handleCloseModal = () => {
    setVisible(false);
    setCartData([]);
  };

  return userId ? (
    <div className="container mx-auto p-8">
      <Table
        dataSource={userIds}
        columns={columns}
        rowKey="userId"
        scroll={{ x: true }}
      />
      <Modal
        title="Carts"
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
        width={800}
      >
        <List
          grid={{ gutter: [16, 16], column: 3 }}
          dataSource={cartData}
          renderItem={(item) => (
            <List.Item key={item.productid}>
              <Card className="bg-blue-200 p-4" title={item.productName}>
                <p className="mb-2">ProductId: {item.productid}</p>
                <p className="mb-2">Quantity: {item.orderQuantity}</p>
                <p>Purchase Price: ₹ {item.productRate.toFixed(2)}</p>
              </Card>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Carts
    </span>
  );
};

export default Carts;
