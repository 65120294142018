import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Modal, Form, Input, message, Spin } from "antd";
import { BASE_URL } from "../constants/Constants";

const Subcategories = () => {
  const userId = localStorage.getItem("userId");
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const [updateSubcategoryName, setUpdateSubcategoryName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [newSubcategoryDescription, setNewSubcategoryDescription] =
    useState(null);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteConfirmModalVisible, setIsDeleteConfirmModalVisible] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    fetchData();
    updateCategoryId();
  }, []);

  const updateCategoryId = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/category/all`);
      const data = response.data.map((category) => ({
        ...category,
        categoryId: category.categoryId, // You might not need to update it if it's already named categoryId
      }));
      setCategories(data);
      setCategoryId(data[0].categoryId); // Update categoryId here
      console.log("ghb", categoryId);
    } catch (error) {
      console.error("Error updating category id:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/category/all-unhide`);

      setCategories(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    setIsLoading(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showUpdateModal = (categoryId, subCategoryId, subCategoryName) => {
    setSelectedCategoryId(categoryId);
    setSelectedSubcategoryId(subCategoryId);
    setUpdateSubcategoryName(subCategoryName);
    // const [isLoading, setIsLoading] = useState(false);
    form.setFieldsValue({ updateSubcategoryName: subCategoryName }); // Set the value of the form field
    setIsUpdateModalVisible(true);
  };
  const handleCancelUpdate = () => {
    setIsUpdateModalVisible(false);
  };

  const handleNewSubcategory = (categoryId) => {
    form.resetFields();
    setIsModalVisible(true);
    setSelectedCategoryId(categoryId);
  };

  const showDeleteConfirmModal = (product) => {
    setSelectedSubcategoryId(product);
    setIsDeleteConfirmModalVisible(true);
  };

  const hideDeleteConfirmModal = () => {
    setSelectedSubcategoryId(null);
    setIsDeleteConfirmModalVisible(false);
  };

  const handleDeleteSubcategory = async () => {
    try {
      setIsDeleting(true);
      await axios.delete(
        `${BASE_URL}/api/subcategories/${selectedSubcategoryId}`
      );

      message.success("Subcategory deleted successfully");
      setIsDeleteConfirmModalVisible(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting subcategory:", error.message);
      console.error("Full error response:", error.response);
      message.error("Failed to delete subcategory");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCreateSubcategory = async () => {
    try {
      setIsCreating(true);
      console.log(
        newSubcategoryName,
        newSubcategoryDescription,
        selectedCategoryId
      );
      const response = await axios.post(`${BASE_URL}/api/subcategories`, {
        name: newSubcategoryName,
        description: newSubcategoryDescription,
        categoryId: selectedCategoryId,
      });

      console.log("New Subcategory:", response.data.subcategory);
      message.success("Subcategory created successfully");
      setIsModalVisible(false);

      fetchData();
    } catch (error) {
      console.error("Error creating subcategory:", error.message);
      message.error("Failed to create subcategory", error.message);
    } finally {
      setIsCreating(false);
    }
  };

  const handleUpdateSubcategory = async () => {
    try {
      setIsUpdating(true);
      await axios.put(
        `${BASE_URL}/api/subcategories/${selectedSubcategoryId}`,
        {
          name: updateSubcategoryName,
        }
      );

      message.success("Subcategory updated successfully");
      setIsUpdateModalVisible(false);
      fetchData();
    } catch (error) {
      console.error("Error updating subcategory:", error.message);
      console.error("Full error response:", error.response);
      message.error("Failed to update subcategory");
    } finally {
      setIsUpdating(false);
    }
  };

  const columns = [
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
      width: "15%",
    },
    {
      title: "Subcategories",
      dataIndex: "subcategoryList",
      key: "subcategoryList",
      width: "65%",
      render: (subcategoryList) => (
        <ul
          style={{
            padding: 0,
            margin: 0,
            listStyle: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "60%",
          }}
        >
          {subcategoryList.map((subcategory, record) => (
            <li
              key={subcategory.subCategoryId}
              style={{
                borderBottom: "1px solid #ddd",
                marginBottom: 8,
                padding: "8px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "150%",
              }}
            >
              <span>{subcategory.subCategoryName}</span>

              <button
                onClick={() =>
                  showUpdateModal(
                    record.categoryId,
                    subcategory.subCategoryId,
                    subcategory.subCategoryName
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.3rem"
                  viewBox="0 0 512 512"
                  className="mx-auto"
                  fill="#305D2B"
                >
                  {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </button>

              <button>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.3rem"
                  viewBox="0 0 448 512"
                  fill="#EF4D48"
                  className="mx-auto"
                  onClick={() =>
                    showDeleteConfirmModal(subcategory.subCategoryId)
                  }
                >
                  {/*!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.*/}
                  <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                </svg>
              </button>
              {/* <Button
                type="danger"
                className="bg-red-500 ml-10 float-right"
                size="small"
               
              >
                Delete
              </Button> */}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "New Subcategory",
      dataIndex: "categoryName",
      key: "newSubcategory",
      width: "20%",
      render: (categoryName, record) => (
        <button
          className="w-[30px] h-[30px]"
          onClick={() => handleNewSubcategory(record.categoryId)}
        >
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADWElEQVR4nO2ay08UQRDGf0Zk12BEFhCPcjQY9Z9QQVGBG4o3DV5Egl59nNGTCQl/hwYJJGoQ3w/Ui4LISeVgxJsLBM2ait8knbiz7M727s4SvmSyj66prpqurq7+emATGxcpoAu4CYwBs8BPYFWXff+oNpM5BTQQEySBs8Ak8AfIFHj9BiaAPiBRCQe2A5eBRceoFeABcFUjs09PfJuuBv1nbdeAh7onuP8bMKSHUxYcBxYcA14B54D6CLp2AeeB146+z0AHJYQ9qVGnwzfAEY/624G3jv6RUozOHhluHfwCLgJbfXfCP52XgLQz2i2+lLdquDPKOvspPQ4Ac+pzXjYUhWZH4UugifKhAXisvhcUFZGQdMLpKVBH+VEHPHfCLNKcGXXCyRa7SqHRiQpLAAWn2GBil2NO5DNn0rLJslvei12wTlh2igsGncmfV4hdcdaJUqTYqKgB3sk2cyonEioVTPgw8cMx2ba43qj0ORkijtjiZNLTuQQnJWS1ky+8AJ551NcvG8fDBFIqq1ciFoBhCGonnwvlKrAWZme3OryPX/h2xDAlnSfIgltqtP1E3B25IZ3D2RrH1HiyChzpls472Ro/qdF2cXF3pE06jRf4D0tqTFWBI03S+T1b46oaayMono5APASX3VsoEg5PEBtHHvl2ZKmKQqs5V2htmMk+pkZjAOPuSE+u9BssiEaeVfWC2KVGYwurpUTpDCvGgqLRGMC4OpJyisadYUIT6tRoTF+Yjphiw3BBNt4LlQDOSMi42LhurGZkY+96C81XCR4lfuiUbV/yOYYYkvBMDMmH97JtIJ8bkg7Xa4RyXDAkm+YKORTq0E1pkWOVxiFgWTYVfJQx4jwBoy0rhWaRcmbL7SgKkqKFMiKSK0Fi7xALk9FnopinMescK9jvciEFPHGO44o+8Gl1htbC7CDlmRPz6tOq8r2+FLc4YZYW92rp0DdqlJ2WnXDa7buTpJMAMiKUOzyu2HaUEawTwcQu6dl7uzPsAWvfH/EthpRqp6DsyCiUfJ4Wrzs6g045k1FFauX1dfFObUrbtboadWjUI5kphycIyo6BSr0BkRArPq4tQKHEw5p2pr2VciAb6sXF2q7trs4efzgv1dj3D9qeDks2dD+xCaocfwGfzEwBMfegNQAAAABJRU5ErkJggg=="></img>
        </button>
      ),
    },
  ];

  return userId ? (
    <div style={{ padding: 20 }}>
      <h1 className="my-3 md:text-3xl">Categories and Subcategories</h1>

      {isLoading ? (
        <Spin size="large" /> // Render Spin component from antd when loading
      ) : (
        <Table
          dataSource={categories}
          columns={columns}
          rowKey="_id"
          pagination={false}
          bordered
          size="middle"
          scroll={{ x: true }}
        />
      )}
      <Modal
        title="Create New Subcategory"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleCreateSubcategory}
        okType="default"
        confirmLoading={isCreating}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Subcategory Name"
            name="newSubcategoryName"
            rules={[
              { required: true, message: "Please enter the subcategory name" },
            ]}
          >
            <Input onChange={(e) => setNewSubcategoryName(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Subcategory"
        visible={isUpdateModalVisible}
        onCancel={handleCancelUpdate}
        onOk={handleUpdateSubcategory}
        okType="default"
        confirmLoading={isUpdating}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Subcategory Name"
            name="updateSubcategoryName"
            rules={[
              { required: true, message: "Please enter the subcategory name" },
            ]}
          >
            <Input
              value={updateSubcategoryName}
              onChange={(e) => setUpdateSubcategoryName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Deletion"
        visible={isDeleteConfirmModalVisible}
        onOk={handleDeleteSubcategory}
        onCancel={hideDeleteConfirmModal}
        cancelText="No"
        okType="danger"
        confirmLoading={isDeleting}
      >
        <p>Are you sure you want to delete this Subcategory?</p>
      </Modal>
    </div>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Subcategories
    </span>
  );
};

export default Subcategories;
