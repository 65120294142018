import React, { useState, useEffect } from "react";
import { Modal, DatePicker, Button } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import "antd/dist/antd.css";
import "tailwindcss/tailwind.css";
import "jspdf-autotable";
import SalesReportTable from "../components/SalesReportTable";
import { BASE_URL } from "../constants/Constants";
import axios from "axios";

const { RangePicker } = DatePicker;

const SalesReportPage = () => {
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    fetchOrders();
  }, [startDate, endDate]);

  const fetchOrders = async () => {
    try {
      let apiUrl = `${BASE_URL}/orders/allOrdersList?size=1000000`;

      console.log("API URL:", apiUrl);

      const response = await axios.get(apiUrl);
      const data = response.data.content;
      console.log("Filtered Data:", data);

      // Flatten the products array from each order into a single array
      const products = data.flatMap((product) => ({
        productName: product.productName || "Default Product Name",
        productQuantity: product.orderQuantity || "Default Quantity",
        mrp: product.productRate,
        amount:
          product.productRate * (product.orderQuantity || 1) ||
          "Default Amount",
        orderId: product.serialNo,
        createdAt: product.billDate,
      }));
      console.log("efewe", products);
      setTotalAmount(products.amount);
      setData(products);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDateChange = () => {
    // Filter orders based on the selected start date and end date
    const filteredOrders = data.filter((order) => {
      const orderDate = moment(order.createdAt);
      return (
        orderDate.isSameOrAfter(startDate, "day") &&
        orderDate.isSameOrBefore(endDate, "day")
      );
    });

    console.log("Filtered Orders:", filteredOrders);
    setData(filteredOrders);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    fetchOrders();
  };

  const downloadPDF = () => {
    const pdf = new jsPDF();

    // Calculate minimum and maximum dates
    const dates = data.map((item) => moment(item.createdAt));
    const minDate = moment.min(dates).format("DD-MMM-YYYY");
    const maxDate = moment.max(dates).format("DD-MMM-YYYY");

    // Add the heading and dates
    pdf.setFontSize(16);
    pdf.text("Divine Depot - Product Sales", 20, 20);
    pdf.setFontSize(12);
    pdf.text(`From: ${minDate} To: ${maxDate}`, 20, 30);

    const rows = data.map((item) => [
      item.orderId,
      item.productName,
      item.productQuantity,
      item.mrp,
      item.amount,
      moment(item.createdAt).format("DD-MMM-YYYY h:mm A"),
    ]);

    const overallAmount = data.reduce(
      (total, item) =>
        total + (typeof item.amount === "number" ? item.amount : 0),
      0
    );
    pdf.autoTable({
      startY: 40,
      head: [
        [
          "Order ID",
          "Product Name",
          "Quantity",
          "Product Price",
          "Total Amount",
          "Created At",
        ],
      ],
      body: rows,
    });

    pdf.text(
      `Total Amount: Rs ${overallAmount}`,
      20,
      pdf.autoTable.previous.finalY + 10
    );

    pdf.save("sales_report.pdf");
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "serialNo",
      key: "serialNo",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Product Quantity",
      dataIndex: "orderQuantity",
      key: "orderQuantity",
    },
    {
      title: "Product price",
      dataIndex: "productRate",
      key: "productRate",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Created At",
      dataIndex: "billDate",
      key: "billDate",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  return userId ? (
    <>
      <div className="my-5 float-right">
        <DatePicker placeholder="Start Date" onChange={handleStartDateChange} />
        <DatePicker
          placeholder="End Date"
          onChange={handleEndDateChange}
          style={{ marginLeft: 10 }}
        />
        <Button
          type="primary"
          onClick={handleDateChange}
          className="bg-blue-500"
          style={{ marginLeft: 10 }}
        >
          Apply Filters
        </Button>
        <Button
          type="primary"
          className="bg-blue-500"
          onClick={downloadPDF}
          style={{ marginLeft: 10 }}
        >
          Download PDF
        </Button>
        <Button
          type="default"
          className="bg-blue-500 text-white"
          onClick={handleResetFilters}
          style={{ marginLeft: 10 }}
        >
          Reset Filters
        </Button>
      </div>

      <SalesReportTable data={data} columns={columns} rowKey={"billNo"} />

      {/* Modal for displaying order details */}
      <Modal
        title="Order Details"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
      >
        {/* Render your modal content here */}
      </Modal>
    </>
  ) : (
    <span
      style={{
        fontSize: "20px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      Sign in to see Product Sales
    </span>
  );
};

export default SalesReportPage;
